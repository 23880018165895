import Vue from "vue";
import checkTokenExpiration from "../use/checkTokenExpiration";
import validateBatchNumber from "../use/validateBatchNumber";
import loginWithMagicCode from "../use/loginWithMagicCode";
import store from "../store";

import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Downloads from "../views/Downloads.vue";
import Login from "../views/Login/Index.vue";
import Registration from "../views/Registration.vue";
import Products from "../views//Products/Index.vue";
import ProductDetail from "../views/Products/Id.vue";
import Order from "../views/Order";
import DiabetesTypeOne from "../views/DiabetesTypeOne";
import DiabetesTypeTwo from "../views/DiabetesTypeTwo";
import AboutDiabetes from "../views/AboutDiabetes";
import ActivateAccount from "../views/ActivateAccount";
import SmartPen from "../views/SmartPen";
import RecoveryMail from "../views/RecoveryMail";
import ResetPassword from "../views/ResetPassword";
import DiabetesTools from "../views/DiabetesTools";
import OrderSummary from "../views/OrderSummary";
import OrderList from "../views/OrderList";
import UserSetting from "../views/UserSetting";
import OrderDetail from "../views/OrderDetail";
import GuardLimits from "../views/Articles/GuardLimits";
import WrongProduct from "../views/WrongProduct";
import Stock from "../views/Stock";
import Users from "../views/Users";
import News from "../views/News";
import Stickers from "../views/Stickers";
import PageNotFound from '../views/PageNotFound.vue';
import AppPolicy from '../views/AppPolicy.vue';
import hf28ng from '../views/Pdfs/hf28ng.vue';
import j29fj1 from '../views/Pdfs/j29fj1.vue';
import Patients from '../views/Patients/Index.vue';
import PatientDetail from '../views/Patients/Id.vue'
import PatientRequests from '../views/Patients/Requests.vue'
import ActivateNurseAccount from '../views/ActivateNurseAccount.vue'
import RegistrationVerify from '../views/RegistrationVerify.vue'
import PdfReport from '../views/PdfReport.vue'




Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/ke-stazeni",
    name: "Downloads",
    component: Downloads,
  },
  {
    path: "/prihlaseni",
    name: "Login",
    component: Login,
  },
  {
    path: "/registrace",
    name: "Registration",
    component: Registration,
  },
  {
    path: "/produkty",
    name: "Products",
    meta: "Authorized",
    component: Products,
  },
  {
    path: "/produkty/:name",
    name: "ProductDetail",
    meta: "Authorized",
    component: ProductDetail,
  },
  {
    path: "/objednavka",
    name: "Order",
    meta: "Authorized",
    component: Order,
  },
  {
    path: "/cukrovka-typu-1",
    name: "DiabetesTypeOne",
    component: DiabetesTypeOne,
  },
  {
    path: "/cukrovka-typu-2",
    name: "DiabetesTypeTwo",
    component: DiabetesTypeTwo,
  },
  {
    path: "/o-cukrovce",
    name: "AboutDiabetes",
    component: AboutDiabetes,
  },
  {
    path: "/potvrzeni-registrace",
    name: "RegistrationVerify",
    component: RegistrationVerify,
  },
  {
    path: "/activate-account",
    name: "ActivateAccount",
    component: ActivateAccount,
  },
  {
    path: "/chytra-pera",
    name: "SmartPen",
    component: SmartPen,
  },
  {
    path: "/reset-hesla",
    name: "RecoveryMail",
    component: RecoveryMail,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/pomucky-pro-diabetiky",
    name: "DiabetesTools",
    component: DiabetesTools,
  },
  {
    path: "/shrnuti-objednavky",
    name: "OrderSummary",
    meta: "Authorized",
    component: OrderSummary,
  },
  {
    path: "/objednavky",
    name: "OrderList",
    meta: "Authorized",
    component: OrderList,
  },
  {
    path: "/objednavky/:id",
    name: "OrderDetail",
    meta: "Authorized",
    component: OrderDetail,
  },
  {
    path: "/nastaveni-uctu",
    name: "UserSetting",
    component: UserSetting,
  },
  {
    path: "/aktuality/ochranne-limity",
    name: "GuardLimits",
    component: GuardLimits,
  },
  {
    path: "/aktuality",
    name: "News",
    component: News,
  },
  {
    path: "/sklad",
    name: "Stock",
    component: Stock,
    meta: "Authorized",
  },
  {
    path: "/uzivatele",
    name: "Users",
    component: Users,
    meta: "Authorized",
  },
  {
    path: "/samolepky",
    name: "Stickers",
    component: Stickers,
    meta: "Authorized",
  },
  {
    path: "/error",
    name: "WrongProduct",
    component: WrongProduct,
  },
  {
    path: "/pacienti",
    name: "Patients",
    component: Patients,
    meta: "Authorized",
  },

  {
    path: "/pacienti/:id/:tab",
    name: "PatientDetail",
    component: PatientDetail,
    meta: "Authorized",
  },

  {
    path: "/patients/requests",
    name: "PatientRequests",
    component: PatientRequests,
    meta: "Authorized",
  },

  {
    path: "/app-policy",
    name: "AppPolicy",
    component: AppPolicy,
  },

  {
    path: "/activate-nurse-account",
    name: "ActivateNurseAccount",
    component: ActivateNurseAccount,
  },


  {
    path: "/pdf/hf28ng",
    name: "hf28ng",
    component: hf28ng,
  },
  {
    path: "/pdf/j29fj1",
    name: "j29fj1",
    component: j29fj1,
  },
  {
    path: '*', // This will catch all navigations that didn't match.
    component: PageNotFound,
  },
  {
    path: '/pdf-report/:id',
    name: 'PdfReport',
    component: PdfReport,
    meta: "unAuthorized"
  }

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const validToken = checkTokenExpiration();
  const routeHasCodeParam = to.query.code
  const routeHasMagicCodeParam = to.query.magicCode
  const batchNumberFromCodeParam = { batchNumber: to.query.code };
  const storeBatchNumbers = store.getters.getBatchNumbers

  let isAuthorized = validToken || store.getters.getBatchNumbers

  const checkDuplicateBatchNumbers = async () => {
    // Check if the batch number already exists in the storeBatchNumbers array
    const exists = storeBatchNumbers.some(
      batchNumber => {
        return batchNumber.toUpperCase() === batchNumberFromCodeParam.batchNumber.toUpperCase()
      }
    );

    // If it doesn't exist, commit it to the store
    if (!exists) {
      if (await validateBatchNumber(routeHasCodeParam)) {
        store.commit("addBatchNumber", batchNumberFromCodeParam);
      }
    }
  };
  if (routeHasCodeParam && to.name !== "ActivateAccount") {

    if (storeBatchNumbers) {
      await checkDuplicateBatchNumbers()
    } else {
      if (await validateBatchNumber(routeHasCodeParam)) {
        store.commit("setBatchNumbers", [])
        store.commit("addBatchNumber", batchNumberFromCodeParam)
      }
    }
  }

  // If the route has a magic code param, log in the user
  if (routeHasMagicCodeParam) {

    const type = to.query.type
    const magicCode = to.query.magicCode
    const payload = {
      magicCode,
      staySignedIn: true,
      isMobile: false
    }

    if (!type || !magicCode) {

      next({
        path: '404',

      })

      return
    }


    const response = await loginWithMagicCode(type, payload)


    store.commit("setUserInfo", { user: response.data.user })
    store.commit("setAuth", response.data.auth)

    next({
      path: to.path,
      replace: true // This ensures the current path is replaced, not added

    })

    return
  }



  // || await validateBatchNumbers()

  if (to.meta === "Authorized" && !isAuthorized) {
    next({
      name: "Login",
      query: { redirect: to.fullPath }, // Pass the original route as a query parameter
    });
  } else if (to.name === "Login" && isAuthorized) {
    // User is already authenticated, redirect to product page
    next({
      name: "Products",
    });
  } else {
    next();
  }
});

export default router;
