<template>
  <div class="tresiba-detail">
    <div class="container">
      <div class="tresiba-detail__header">
        <div class="text-h4 title">
          Moje léčba inzulinovým perem NovoPen<sup>®</sup> 6 a NovoPen Echo
          Plus<sup>®</sup>
        </div>
      </div>
      <div class="tresiba-detail__text">
        <Human
          v-if="$vuetify.breakpoint.mdAndUp"
          class="human-illustration"
          fill="#0f2d69"
        />
        <div>
          <p>
            NovoPen<sup>®</sup> 6 a NovoPen Echo<sup>®</sup> Plus jsou nová
            chytrá inzulinová pera, která automaticky zaznamenávají informace o
            užitých dávkách inzulinu pokaždé, když si ho aplikujete. Vedle toho
            disponují nová chytrá pera displejem, který zobrazuje přesné
            informace o vaší poslední aplikované dávce inzulinu. Na displeji
            pera se zobrazuje počet jednotek v poslední podané dávce a také čas,
            který uplynul od poslední aplikace. Od teď již nemusíte přemýšlet,
            zda, kolik a kdy jste si naposledy inzulin aplikovali.
          </p>
          <v-img
            class="mb-8"
            src="https://novocare.b-cdn.net/chytra%20pera/Barevné%20variace-min.PNG"
          ></v-img>
          <p>
            Specifikace NovoPen<sup>®</sup> 6 & NovoPen Echo<sup>®</sup> Plus
          </p>
          <ul class="mb-8">
            <li>Automaticky zaznamenává posledních 800 aplikací.</li>
            <li>
              Bezdrátový přenos údajů pacienta technologií Near Field
              Communication.
            </li>
            <li>
              Displej zobrazuje velikost poslední dávky a čas od poslední
              aplikace.
            </li>
            <li>Snadné použití, snadné odečty údajů.</li>
            <li>Není třeba měnit ani dobíjet baterii.</li>
            <li>Životnost 4 až 5 let.</li>
            <li>
              Kompatibilní se zásobními vložkami Penfill<sup>®</sup> 3 ml od
              společnosti Novo Nordisk.
            </li>
          </ul>

          <p>
            Jakým způsobem lze stáhnout informace o aplikovaných dávkách
            inzulinu?
          </p>

          <p>
            Informace o dávkách inzulinu můžete přenést do své oblíbené
            kompatibilní aplikace a sledovat je společně s údaji o hodnotě
            hladiny cukru v krvi (z&nbsp;glukometru nebo senzoru). Zjistíte tak,
            jak jednotlivé aplikace inzulinu ovlivňují vaši hladinu cukru v
            krvi.
          </p>
          <p>
            Pokud máte k dispozici chytrý telefon*, tablet nebo jiné zařízení,
            které podporuje NFC (bezdrátovou komunikaci na krátkou vzdálenost),
            můžete informace o jednotlivých aplikacích inzulinu jednoduše
            přenášet ze svého chytrého pera do příslušného zařízení.
          </p>
          <p>
            Stačí si stáhnout kompatibilní aplikaci, která bude schopna načíst a
            zobrazit vaše údaje.
          </p>
          <h2 class="primary--text">
            Chytrá inzulinová pera NovoPen<sup>®</sup> 6 a NovoPen Echo<sup
              >®</sup
            >
            Plus spolupracují s následujícími aplikacemi
          </h2>
          <v-container class="pa-0 my-8">
            <v-row>
              <v-col cols="12" md="4">
                <div class="card">
                  <v-img
                    class="ma-auto"
                    max-width="150"
                    height="250"
                    contain
                    src="https://novocare.b-cdn.net/chytra%20pera/LOWRES~1.PNG"
                  ></v-img>
                  <div>
                    <div class="text-h6 mb-4">MySugr</div>
                    <p>
                      Aplikace umožňuje zobrazovat data z&nbsp;glukometru
                      Accu-Chek<sup>®</sup> Instant spolu s daty z chytrého
                      inzulinového pera. Chytré inzulinové pero NovoPen<sup
                        >®</sup
                      >
                      6 nebo NovoPen Echo<sup>®</sup> Plus lze načíst pouze v
                      aplikaci pro operační systém iOS.
                    </p>
                    <p>
                      Více informací o mobilní aplikaci naleznete
                      <a
                        href="https://www.accu-chek.cz/cz/microsites/mySugr/index.html"
                        target="_blank"
                        >zde</a
                      >.
                    </p>
                    <div
                      class="d-flex flex-column flex-md-row justify-space-between download-imgs"
                    >
                      <a
                        href="https://apps.apple.com/cz/app/mysugr-diabetes-tracker-log/id516509211?l=cs"
                        target="_blank"
                      >
                        <v-img
                          class="mr-4 mb-4 mb-md-0"
                          max-width="120"
                          src="https://novocare.b-cdn.net/chytra%20pera/Download_tlačítko_apple-removebg-preview%20(1).png"
                        ></v-img>
                      </a>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.mysugr.android.companion"
                        target="_blank"
                      >
                        <v-img
                          max-width="120"
                          src="https://novocare.b-cdn.net/chytra%20pera/Download_tlačítko_google-removebg-preview.png"
                        ></v-img>
                      </a>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="card">
                  <v-img
                    class="ma-auto"
                    max-width="200"
                    height="250"
                    contain
                    src="https://novocare.b-cdn.net/glooko.png"
                  ></v-img>
                  <div>
                    <div class="text-h6 mb-4">Glooko<sup>®</sup></div>
                    <p>
                      Aplikace umožňuje zobrazovat údaje o&nbsp;dávkách inzulinu
                      z chytrého inzulinového pera spolu s daty ze CGM a
                      glukometrů.
                    </p>
                    <p>
                      Více informací o propojení s chytrými pery v aplikaci
                      Glooko<sup>®</sup> naleznete
                      <a
                        href="https://glooko.com/glucose_meter/novopen-6/"
                        target="_blank"
                        >zde</a
                      >.
                    </p>
                    <div
                      class="d-flex flex-column flex-md-row justify-space-between download-imgs"
                    >
                      <a
                        href="https://apps.apple.com/us/app/glooko-track-diabetes-data/id471942748"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <v-img
                          class="mr-4 mb-4 mb-md-0"
                          max-width="120"
                          src="https://novocare.b-cdn.net/chytra%20pera/Download_tlačítko_apple-removebg-preview%20(1).png"
                        ></v-img>
                      </a>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.glooko.logbook"
                        target="_blank"
                      >
                        <v-img
                          max-width="120"
                          src="https://novocare.b-cdn.net/chytra%20pera/Download_tlačítko_google-removebg-preview.png"
                        ></v-img
                      ></a>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="card">
                  <v-img
                    max-width="200"
                    height="250"
                    class="ma-auto"
                    contain
                    src="https://novocare.b-cdn.net/chytra%20pera/Logo%20FSLL%20Primary%20Vertical.png"
                  ></v-img>
                  <div class="text-h6 mb-4">
                    FreeStyle LibreLink<sup>®</sup>
                  </div>
                  <p>
                    Aplikace pro zobrazení dat ze senzorů FreeStyle Libre spolu
                    s daty o dávkách inzulinu z chytrých inzulinových per.
                  </p>
                  <div class="d-flex justify-space-between download-imgs">
                    <a
                      href="https://apps.apple.com/cz/app/freestyle-librelink-cz/id1620058766?l=cs"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <v-img
                        class="mr-4 mb-4 mb-md-0"
                        max-width="120"
                        src="https://novocare.b-cdn.net/chytra%20pera/Download_tlačítko_apple-removebg-preview%20(1).png"
                      ></v-img>
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.freestylelibre.app.cz"
                      target="_blank"
                    >
                      <v-img
                        max-width="120"
                        src="https://novocare.b-cdn.net/chytra%20pera/Download_tlačítko_google-removebg-preview.png"
                      ></v-img>
                    </a>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>

          <h2 class="primary--text mb-4">
            Návod pro přetažení dat z chytrého pera do příslušného zařízení
          </h2>
          <p>
            Přidržte displej pera přímo u označení NFC na příslušném
            elektronickém zařízení.
          </p>
          <p>
            Chytrá inzulinová pera jsou kompatibilní s telefony s operačními
            systémy Android a iOS. Lokalita NFC přenosového snímače se liší dle
            typu operačního systému a telefonu.
          </p>
        </div>
        <h2 class="primary--text mb-4">NovoPen<sup>®</sup> 6 Videonávod</h2>
        <div style="width: 100%; vertical-align: bottom" class="mb-12">
          <div style="padding: 56.25% 0 0 0; position: relative">
            <iframe
              src="https://player.vimeo.com/video/766840088?h=dd8eeab5a2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
              title="NovoPen 6 - videon&amp;aacute;vod k použit&amp;iacute;"
            ></iframe>
          </div>
        </div>

        <h2 class="primary--text mb-4">
          NovoPen Echo Plus<sup>®</sup> - videonávod
        </h2>

        <div style="padding: 56.25% 0 0 0; position: relative">
          <iframe
            src="https://player.vimeo.com/video/771615327?h=cd687855f4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            title="NovoPen Echo Plus - videon&amp;aacute;vod"
          ></iframe>
        </div>

        <Earth
          v-if="$vuetify.breakpoint.mdAndUp"
          class="earth-illustration"
          fill="#0f2d69"
        />
        <Hand
          v-if="$vuetify.breakpoint.mdAndUp"
          class="hand-illustration"
          fill="#0f2d69"
        />
      </div>
      <!-- <v-img
        max-width="400"
        class="microscope-illustration"
        src="../../assets/img/illustrations/detail-microscope.svg"
      ></v-img> -->
      <Microscope
        v-if="$vuetify.breakpoint.mdAndUp"
        class="microscope-illustration"
        fill="#0f2d69"
      />
    </div>
  </div>
</template>

<script>
import Earth from "../illustration/Earth.vue";
import Hand from "../illustration/Hand.vue";
import Human from "../illustration/Human.vue";
import Microscope from "../illustration/Microscope.vue";
export default {
  components: { Microscope, Hand, Earth, Human },
};
</script>

<style lang="scss" scoped>
.tresiba-detail {
  &__header {
    display: inline;
    .title {
      display: inline-block;
      margin-bottom: 1rem;
    }
    position: relative;
  }

  &__text {
    position: relative;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    padding: 2rem;
    border-radius: 1rem;
    background-color: white;

    .human-illustration {
      position: absolute;
      right: -5rem;
      top: -9rem;
      max-width: 10rem;
    }
    .earth-illustration {
      position: absolute;
      left: -8.3rem;
      top: 40%;
    }

    .hand-illustration {
      position: absolute;
      max-width: 10rem;
      right: -7.5rem;
      bottom: -8rem;
    }
  }

  .card {
    position: relative;
    background: #dee7f7;
    color: var(--v-primary-base);
    padding: 2rem;
    padding-bottom: 8rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .download-imgs {
    position: absolute;
    bottom: 1rem;
  }

  .microscope-illustration {
    max-width: 32rem;
    margin-left: 10rem;
    margin-top: 0.5rem;
  }
}

@media screen and (min-width: 960px) {
  .tresiba-detail {
    .card {
      min-height: 40rem;
      padding-bottom: 0;
    }

    .card-wrapper {
      margin-top: 8rem;
    }
  }
}
</style>
