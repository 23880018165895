<template>
  <div>
    <div class="calendar no-break">
      <div
        v-for="(month, monthIndex) in months"
        :key="monthIndex"
        class="month no-break"
      >
        <h3 class="mb-4">{{ month.name }}</h3>
        <div class="calendar-row">
          <div v-for="dayName in dayNames" :key="dayName" class="day-name">
            {{ dayName }}
          </div>
        </div>
        <div
          v-for="(week, weekIndex) in month.weeks"
          :key="weekIndex"
          class="calendar-row"
        >
          <div
            v-for="(day, dayIndex) in week"
            :key="dayIndex"
            class="day"
            :class="{ 'empty-day': !day }"
          >
            <div class="m-auto" v-if="day">
              <div>
                <div class="pa-4">
                  <div>
                    {{ day }}
                  </div>
                  <v-icon
                    :color="
                      getStepsColor(`${month.year}-${month.number}-${day}`)
                    "
                    class="mt-2"
                    size="30"
                    >mdi-run</v-icon
                  >

                  <div>
                    <small>
                      {{
                        getDaySteps(
                          `${month.year}-${month.number}-${day}`
                        ).toLocaleString("cs-CZ")
                      }}
                      <div>kroků</div>
                    </small>
                  </div>

                  <!-- <v-icon :color="getStepsColor(date)" class="mt-2" size="30"
                    >mdi-run</v-icon
                  >
                  <div>
                    <small>
                      {{ getDaySteps(date).toLocaleString("cs-CZ") }}
                      <div>kroků</div>
                    </small>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useApiCall from "@/use/apiCall";
import useFormatDate from "@/use/formatDate";

export default {
  setup() {
    const { getData } = useApiCall();
    const { getDateToSend } = useFormatDate();

    return {
      getData,
      getDateToSend,
    };
  },

  props: {
    patient: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dayNames: ["Po", "Út", "St", "Čt", "Pá", "So", "Ne"], // Czech localized day names starting with Monday
      months: [],
      thresholds: {
        below60: [2999, 6999, 7000],
        between60And79: [1999, 5999, 6000],
        above80: [999, 3999, 4000],
      },
      steps: [],
    };
  },

  computed: {
    from() {
      return this.$route.query.from;
    },
    to() {
      return this.$route.query.to;
    },

    patientAge() {
      return new Date().getFullYear() - this.patient.birthYear;
    },
  },

  methods: {
    getDayIntake(date) {
      const intake = this.intakes.find((i) => {
        const formattedDate = this.getDateToSend(i.createdAtLocal);

        const ensureLeadingZeros = (dateString) => {
          const [year, month, day] = dateString.split("-");
          return `${year}-${String(month).padStart(2, "0")}-${String(
            day
          ).padStart(2, "0")}`;
        };

        if (formattedDate === ensureLeadingZeros(date)) {
          return i;
        }
      });

      return intake;
    },

    getStepsColor(date) {
      const steps = this.getDaySteps(date);
      const age = this.patientAge;

      const getStepColor = (steps, thresholds) => {
        if (steps <= thresholds[0]) {
          return "red";
        } else if (steps <= thresholds[1]) {
          return "orange";
        } else if (steps >= thresholds[2]) {
          return "green";
        } else {
          return "gray";
        }
      };

      if (age < 60) {
        return getStepColor(steps, this.thresholds.below60);
      } else if (age >= 60 && age <= 79) {
        return getStepColor(steps, this.thresholds.between60And79);
      } else if (age >= 80) {
        return getStepColor(steps, this.thresholds.above80);
      } else {
        return "gray"; // Default case if none of the conditions match
      }
    },

    getDaySteps(date) {
      const ensureLeadingZeros = (dateString) => {
        const [year, month, day] = dateString.split("-");
        return `${year}-${String(month).padStart(2, "0")}-${String(
          day
        ).padStart(2, "0")}`;
      };
      const daySteps = this.steps.find((s) => {
        const formattedDate = this.getDateToSend(s.occurredOnLocal);
        if (formattedDate === ensureLeadingZeros(date)) {
          return s;
        }
      });

      const amount = daySteps?.amount || 0;
      return amount;
    },

    async fetchSteps() {
      this.steps = [];

      const patientId = Number(this.$route.params.id);

      const params = {
        patientId: patientId,
        from: this.getDateToSend(this.from),
        end: this.getDateToSend(this.to),
      };

      const { steps } = await this.getData("steps", {
        params,
      });

      this.steps = steps;
    },

    generateCalendar(start, end) {
      const months = [];
      let currentDate = new Date(start.getFullYear(), start.getMonth(), 1);

      while (currentDate <= end) {
        const month = {
          name: currentDate.toLocaleString("cs-CZ", {
            month: "long",
            year: "numeric",
          }), // Czech locale for month name
          weeks: [], // Weeks containing days or empty slots
          year: currentDate.getFullYear(),
          number: String(currentDate.getMonth() + 1).padStart(2, "0"),
        };

        const daysInMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        ).getDate();

        let firstDay = (currentDate.getDay() + 6) % 7; // Adjust for Monday start (0 = Monday, 6 = Sunday)
        let week = Array(firstDay).fill(null); // Fill initial empty days
        for (let day = 1; day <= daysInMonth; day++) {
          week.push(day);
          if (week.length === 7 || day === daysInMonth) {
            month.weeks.push(week);
            week = [];
          }
        }

        months.push(month);
        currentDate.setMonth(currentDate.getMonth() + 1);
      }

      return months;
    },
  },

  async mounted() {
    this.months = this.generateCalendar(new Date(this.from), new Date(this.to));
    await this.fetchSteps();
  },
};
</script>

<style lang="scss" scoped>
.calendar {
  .month {
    margin-bottom: 2rem;

    .day-name {
      font-weight: bold;
      text-align: center;
      margin-bottom: 0.5rem;
      width: 14%;
    }

    .day {
      text-align: center;
      padding: 0.5rem;
      width: 14%;
      height: 10rem;
      border: 1px solid #e0e0e0;

      &.empty-day {
        background-color: #f5f5f5;
      }
    }
  }
  .calendar-row {
    display: flex;
  }
}

@media print {
  .no-break {
    page-break-inside: avoid; /* Avoid breaking inside the element */
    break-inside: avoid; /* Ensures compatibility with modern browsers */
    display: block;
  }
}
</style>
