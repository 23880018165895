<template>
  <div class="no-break">
    <div class="mt-4 border-card no-break mb-12">
      <div class="title mb-4">Legenda</div>

      <div
        class="d-flex align-center mb-2 no-break"
        v-for="(l, i) in legend"
        :key="i"
      >
        <RybelsusStatusIcon :intake="l" class="mr-2"> </RybelsusStatusIcon>
        <div v-html="l.text"></div>
      </div>

      <div class="d-flex align-center">
        <RybelsusStatusIcon :intake="null" class="mr-2"> </RybelsusStatusIcon>
        <div>
          <div>Chybí záznam o přípravku Rybelsus<sup>®</sup></div>
        </div>
      </div>
    </div>

    <div class="calendar">
      <div
        v-for="(month, monthIndex) in months"
        :key="monthIndex"
        class="month no-break"
      >
        <h3 class="mb-4">{{ month.name }}</h3>
        <div class="calendar-row">
          <div v-for="dayName in dayNames" :key="dayName" class="day-name">
            {{ dayName }}
          </div>
        </div>
        <div
          v-for="(week, weekIndex) in month.weeks"
          :key="weekIndex"
          class="calendar-row"
        >
          <div
            v-for="(day, dayIndex) in week"
            :key="dayIndex"
            class="day"
            :class="{ 'empty-day': !day }"
          >
            <div class="m-auto" v-if="day">
              <RybelsusStatusIcon
                v-if="day"
                :intake="getDayIntake(`${month.year}-${month.number}-${day}`)"
                class="ma-auto"
              >
                {{ day }}
              </RybelsusStatusIcon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RybelsusStatusIcon from "@/components/icons/RybelsusStatusIcon.vue";
import useApiCall from "@/use/apiCall";
import useFormatDate from "@/use/formatDate";

export default {
  components: {
    RybelsusStatusIcon,
  },

  setup() {
    const { getData } = useApiCall();
    const { getDateToSend } = useFormatDate();

    return {
      getData,
      getDateToSend,
    };
  },

  data() {
    return {
      dayNames: ["Po", "Út", "St", "Čt", "Pá", "So", "Ne"], // Czech localized day names starting with Monday
      months: [],

      intakes: [],

      legend: [
        {
          status: "accepted",
          dosageType: "rybelsus3Mg",
          text: "Rybelsus<sup>®</sup> 3 mg byl vzat",
        },
        {
          status: "accepted",
          dosageType: "rybelsus7Mg",
          text: "Rybelsus<sup>®</sup> 7 mg byl vzat",
        },
        {
          status: "accepted",
          dosageType: "rybelsus14Mg",
          text: "Rybelsus<sup>®</sup> 14 mg byl vzat",
        },

        // {
        //   status: "accepted",
        //   dosageType: "rybelsus1P5Mg",
        //   text: "Rybelsus<sup>®</sup> 1,5 mg byl vzat",
        // },
        // {
        //   status: "accepted",
        //   dosageType: "rybelsus4Mg",
        //   text: "Rybelsus<sup>®</sup> 4 mg byl vzat",
        // },
        // {
        //   status: "accepted",
        //   dosageType: "rybelsus9Mg",
        //   text: "Rybelsus<sup>®</sup> 9 mg byl vzat",
        // },
        // {
        //   status: "rejected",
        //   dosageType: "rybelsus3Mg",
        //   text: "Přípravek Rybelsus<sup>®</sup> nebyl vzat",
        // },
      ],
    };
  },

  computed: {
    from() {
      return this.$route.query.from;
    },
    to() {
      return this.$route.query.to;
    },
  },

  methods: {
    getDayIntake(date) {
      const intake = this.intakes.find((i) => {
        const formattedDate = this.getDateToSend(i.createdAtLocal);

        const ensureLeadingZeros = (dateString) => {
          const [year, month, day] = dateString.split("-");
          return `${year}-${String(month).padStart(2, "0")}-${String(
            day
          ).padStart(2, "0")}`;
        };

        if (formattedDate === ensureLeadingZeros(date)) {
          return i;
        }
      });

      return intake;
    },

    async fetchTreatmentIntakes() {
      this.intakes = [];

      const patientId = Number(this.$route.params.id);

      const params = {
        patientId: patientId,
        from: this.getDateToSend(this.from),
        end: this.getDateToSend(this.to),
      };

      const { intakes } = await this.getData("rybelsus/treatments/intakes", {
        params,
      });

      console.log("calendar", intakes);

      this.intakes = intakes;
      this.calendarLoading = false;
    },

    generateCalendar(start, end) {
      const months = [];
      let currentDate = new Date(start.getFullYear(), start.getMonth(), 1);

      while (currentDate <= end) {
        const month = {
          name: currentDate.toLocaleString("cs-CZ", {
            month: "long",
            year: "numeric",
          }), // Czech locale for month name
          weeks: [], // Weeks containing days or empty slots
          year: currentDate.getFullYear(),
          number: String(currentDate.getMonth() + 1).padStart(2, "0"),
        };

        const daysInMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        ).getDate();

        let firstDay = (currentDate.getDay() + 6) % 7; // Adjust for Monday start (0 = Monday, 6 = Sunday)
        let week = Array(firstDay).fill(null); // Fill initial empty days
        for (let day = 1; day <= daysInMonth; day++) {
          week.push(day);
          if (week.length === 7 || day === daysInMonth) {
            month.weeks.push(week);
            week = [];
          }
        }

        months.push(month);
        currentDate.setMonth(currentDate.getMonth() + 1);
      }

      return months;
    },
  },

  async mounted() {
    this.months = this.generateCalendar(new Date(this.from), new Date(this.to));
    await this.fetchTreatmentIntakes();
  },
};
</script>

<style lang="scss" scoped>
.calendar {
  .month {
    margin-bottom: 2rem;

    .day-name {
      font-weight: bold;
      text-align: center;
      margin-bottom: 0.5rem;
      width: 14%;
    }

    .day {
      text-align: center;
      padding: 0.5rem;
      width: 14%;
      height: 4rem;
      border: 1px solid #e0e0e0;

      &.empty-day {
        background-color: #f5f5f5;
      }
    }
  }
  .calendar-row {
    display: flex;
  }
}

@media print {
  .no-break {
    page-break-inside: avoid; /* Avoid breaking inside the element */
    break-inside: avoid; /* Ensures compatibility with modern browsers */
    display: block;
  }

  .new-page {
    page-break-before: always; /* For older browsers */
    break-before: page; /* Modern standard */
  }
}
</style>
