<template>
  <div class="refund-form">
    <div class="text-h4 font-weight-bold mb-4 primary--text">
      Kalkulačka doplatku
    </div>
    <v-row>
      <v-col>
        <v-form
          @submit.prevent="onSubmit"
          class="primary--text"
          v-model="valid"
          ref="form"
        >
          <div class="text-h6 mb-4">
            Na základě zadaných parametrů lze vypočítat náklady na léčbu
            přípravkem Rybelsus® během 1. a 2. roku od zahájení léčby a určit,
            kdy dosáhnete ochranného limitu, po jehož překročení již nebudete
            hradit žádné doplatky.<sup>*</sup>
          </div>

          <v-row align="center">
            <v-col cols="12" md="3">
              <label class="mb-2 d-block"> Název léčivého přípravku </label>
              <input-element
                v-model="calculator.product"
                label="Název léčivo přípravku"
                readonly
              ></input-element>
            </v-col>
            <v-col cols="12" md="3">
              <label class="mb-2 d-block"> Typ pojištěnce </label>
              <v-select
                v-model="calculator.type"
                label="Vyberte typ pojištěnce"
                solo
                outlined
                flat
                :items="insuranceOptions"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <label class="mb-2 d-block"> Začátek léčby </label>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedDate"
                    label="Vyberte měsíc začátku užívání léku"
                    readonly
                    outlined
                    flat
                    :rules="[rules.required]"
                    solo
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="calculator.date"
                  :active-picker.sync="activePicker"
                  type="month"
                  locale="cs-CZ"
                  min="2022-01-01"
                  @change="datePicked"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col md="3">
              <ButtonElement
                class="mb-4 mb-md-0"
                text="Spočítat"
                block
                color="primary"
                depressed
                large
                type="submit"
              >
              </ButtonElement>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import InputElement from "../elements/InputElement.vue";
import useFormatDate from "../../use/formatDate";
import ButtonElement from "../elements/ButtonElement.vue";
import validation from "../../use/validations";

export default {
  components: {
    InputElement,
    ButtonElement,
  },
  setup() {
    const { getCzechDateMonth } = useFormatDate();
    return {
      getCzechDateMonth,
    };
  },
  data() {
    return {
      activePicker: null,
      formattedDate: "",
      valid: false,
      rules: validation,
      menu: false,
      calculator: {
        product: "Rybelsus",
        type: "between18and65",
        date: null,
      },
      insuranceOptions: [
        {
          value: "between18and65",
          text: "Pojištěnec 18 - 64 let",
        },
        {
          value: "between65adn69",
          text: "Pojištěnec 65 - 69 let",
        },
        {
          value: "above70",
          text: "Pojištěnec 70 let + a ZTP",
        },
      ],
    };
  },
  methods: {
    datePicked() {
      this.formattedDate = this.getCzechDateMonth(this.calculator.date);
      this.menu = false;
    },
    onSubmit() {
      this.$refs.form.validate();

      if (!this.valid) {
        return;
      }

      this.$emit("calculateRefund", this.calculator);
    },
  },
};
</script>

<style lang="scss" scoped></style>
