<template>
  <div class="refund-calculator">
    <refund-form
      class="secondary rounded-xl px-8 pt-8 mb-8"
      @calculateRefund="calculateRefund"
    ></refund-form>
    <div class="mb-8">
      <div>
        <small>
          <sup>*</sup>Platí pouze v případě 14 mg dávky LP RYBELSUS®. U 3 mg a 7
          mg pacient doplácí částku nad rámec započitatelného doplatku.
        </small>
      </div>
      <small>
        Částka je vypočítána na základě průměrné ceny přípravku v lékárnách a
        skutečná cena se může lišit. <br />Částka je vypočítána na základě
        dávkovacího schéma: Rybelsus® 3mg po dobu jednoho měsíce, Rybelsus® 7 mg
        po dobu jednoho měsíce a poté Rybelsus® 14mg.
      </small>
    </div>

    <div v-if="$vuetify.breakpoint.lgAndUp">
      <refund-table
        ref="firstYearRefundTable"
        firstYear
        :startDate="firstYearStartDate"
        :insuredType="insuredType"
        @periodEndDate="periodEndDate"
      ></refund-table>
      <refund-table
        ref="secondYearRefundTable"
        :startDate="secondYearStartDate"
        :insuredType="insuredType"
      ></refund-table>
    </div>
    <div v-else ref="mobileContainer">
      <refund-table-mobile
        class="mb-8"
        ref="firstYearRefundTable"
        firstYear
        :startDate="firstYearStartDate"
        :insuredType="insuredType"
        @periodEndDate="periodEndDate"
      ></refund-table-mobile>

      <refund-table-mobile
        class="mb-8"
        ref="secondYearRefundTable"
        :startDate="secondYearStartDate"
        :insuredType="insuredType"
      ></refund-table-mobile>
    </div>
  </div>
</template>

<script>
import RefundForm from "./forms/RefundForm.vue";
import RefundTable from "./table/RefundTable.vue";
import RefundTableMobile from "./table/RefundTableMobile.vue";

export default {
  components: { RefundForm, RefundTable, RefundTableMobile },
  data() {
    return {
      firstYearStartDate: null,
      secondYearStartDate: null,
      insuredType: null,
    };
  },
  methods: {
    calculateRefund(value) {
      this.firstYearStartDate = value.date;
      this.insuredType = value.type;
      this.$refs.firstYearRefundTable.generateRefundTable();
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.scrollToFirstRefundTableMobile();
      }
    },
    periodEndDate(value) {
      const dateString = value;
      const date = new Date(`${dateString}-01`); // Add a dummy day to create a valid date
      date.setMonth(date.getMonth() + 1); // Add one month

      const newDateString = date.toISOString().slice(0, 7); // Format as YYYY-MM

      this.secondYearStartDate = newDateString;
      this.$refs.secondYearRefundTable.generateRefundTable();
    },

    scrollToFirstRefundTableMobile() {
      this.$nextTick(() => {
        // Access the container

        // Find the first element with the 'refund-table-mobile' class
        const firstRefundTableMobile = document.querySelector(
          ".refund-table-mobile"
        );
        const headerOffset = -120;
        const elementPosition =
          firstRefundTableMobile.getBoundingClientRect().top;
        const offsetPosition = elementPosition + headerOffset;

        // If the element exists, scroll to it
        if (firstRefundTableMobile) {
          window.scrollBy({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
