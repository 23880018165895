<template>
  <div class="status" :class="getStatus">
    <v-icon v-if="intake?.status === 'rejected'" color="white"
      >mdi-close</v-icon
    >
    <slot v-else></slot>
  </div>
</template>

<script>
export default {
  props: {
    intake: {
      type: [Object || undefined],
      required: false,
    },
  },

  computed: {
    getStatus() {
      if (this.intake) {
        return `${this.intake.status}-${this.intake.dosageType}`;
      } else {
        return "empty";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.accepted-rybelsus3Mg {
    border: 4px solid #b7dbc2;
  }

  &.accepted-rybelsus7Mg {
    border: 4px solid #efbec3;
  }

  &.accepted-rybelsus14Mg {
    border: 4px solid #9bdaee;
  }

  &.accepted-rybelsus1P5Mg {
    border: 4px solid #0fbc45;
  }

  &.accepted-rybelsus4Mg {
    border: 4px solid #ea6472;
  }

  &.accepted-rybelsus9Mg {
    border: 4px solid #2293b8;
  }

  &.rejected-rybelsus3Mg,
  .rejected-rybelsus7Mg,
  .rejected-rybelsus14Mg {
    background-color: #ff7879;
    color: white;
  }

  &.empty {
    background-color: gainsboro;
  }
}
</style>
