<template>
  <div v-if="startDate" class="refund-table-mobile pa-2">
    <div class="text-center text-h4 primary--text mb-4">
      {{ `${firstYear ? "1" : "2"}. rok léčby` }}
    </div>
    <div class="mb-12 mt-8">
      <v-row class="text-h5 text-md-h4 pa-4 white--text text-center primary">
        <v-col> Skutečně zaplatíte za tuto moderní léčbu </v-col>
      </v-row>
      <v-row class="white--text font-weight-bold text-h5">
        <v-col cols="12" class="text-center accent">
          {{ firstYear ? "1." : "2." }} rok /
          <span class="text-h5 text-md-h4 font-weight-bold"
            >{{ totalPaymentForYear }},-</span
          >
        </v-col>
        <v-col cols="12" class="text-center accent">
          měsíc /
          <span class="text-h5 text-md-h4 font-weight-bold"
            >{{ (totalPaymentForYear / 12).toFixed(0) }},-
          </span>
        </v-col>
        <v-col cols="12" class="text-center accent">
          den /
          <span class="text-h5 text-md-h4 font-weight-bold"
            >{{ (totalPaymentForYear / 365).toFixed(0) }},-</span
          >
        </v-col>
      </v-row>
    </div>
    <v-expansion-panels multiple>
      <v-expansion-panel v-for="quarter in quarters" :key="quarter.id">
        <v-expansion-panel-header class="text-h6 primary--text">
          {{ `${quarter.quarter}. čtvrtletí ${quarter.year}` }}
          <div v-show="false">{{ getColsNumber(quarter) }}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-for="(month, i) in period" :key="i">
            <div
              v-if="
                month.quarter === quarter.quarter && month.year === quarter.year
              "
              class="mb-8"
            >
              <div class="mb-8">
                <div class="mb-4">
                  <div class="text-uppercase font-weight-bold">
                    {{ month.name }}
                  </div>
                  <div class="d-flex mb-2">
                    <div class="mr-4">Dávka:</div>
                    <div>{{ getDosage(i) }}</div>
                  </div>
                </div>

                <div class="d-flex mb-2">
                  <div class="label accent--text mr-4">
                    ZAPOČITATELNÝ DOPLATEK:
                  </div>
                  <div class="accent--text">{{ payments[i].value }},-</div>
                </div>

                <div class="d-flex mb-2">
                  <div class="label primary--text mr-4">ZAPLACENO:</div>
                  <div class="primary--text">{{ costs[i] }},-</div>
                </div>

                <div class="d-flex">
                  <div class="label accent--text mr-4">SKUTEČNÝ DOPLATEK:</div>
                  <div class="accent--text">{{ realCosts[i] }},-</div>
                </div>
              </div>
              <v-divider v-if="i < quarter.endIndex"></v-divider>
            </div>
          </div>
          <!-- <v-divider class="mb-4"></v-divider>
                    <div>
                        <div class="text-right">Přeplatek:</div>
                        <div class="d-flex justify-space-between">
                            <div>
                                <v-img v-if="quarter.refund > 0"
                                    src="https://general-storage.b-cdn.net/Icons/wired-outline-453-savings-pig.gif"
                                    max-width="100"></v-img>
                            </div>
                            <div>
                                <div class="text-h4 mb-4 text-right primary--text font-weight-bold">{{
                                    quarter.refund
                                }},-
                                </div>
                                <div v-if="quarter.refund > 0" class="text-right">
                                    Přeplatek bude vyplacen <br> {{ getRefundDate(quarter) }}
                                    {{ quarter.refundYear ? quarter.refundYear : startYear }}
                                </div>
                            </div>
                        </div>
                    </div> -->
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  props: {
    insuredType: {
      type: String,
      default: null,
    },
    startDate: {
      type: String,
      default: null,
    },
    firstYear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      startMonth: null,
      monthNumber: null,
      period: [],
      costs: [],
      realCosts: [],
      payments: [],
      sumCosts: 0,
      sumPayments: 0,
      sumRefunds: 0,
      quarters: [],
      totalCosts: 0,
      totalRefund: 0,
    };
  },
  methods: {
    getMonth(date) {
      return new Date(date).toLocaleString("cs", {
        month: "long",
      });
    },

    getDosage(i) {
      if (this.firstYear) {
        switch (i) {
          case 0:
            return "3 mg";

          case 1:
            return "7 mg";

          default:
            return "14 mg";
        }
      } else {
        return "14 mg";
      }
    },

    generatePeriod() {
      this.period = [];
      this.payments = [];

      for (let i = 0; i < 12; i++) {
        const startDate = new Date(this.startDate);
        const newDate = startDate.setMonth(startDate.getMonth() + i);

        this.monthNumber = new Date(newDate).getMonth() + 1;
        const formattedDate = this.getMonth(newDate);
        this.period.push({
          name: formattedDate,
          id: this.monthNumber,
          endIndex: i,
          date: startDate,
          year: new Date(startDate).getFullYear(),
        });
        this.payments.push({ id: this.monthNumber });
        this.realCosts.push(0);
      }

      const addCalendarQuarters = (data) => {
        data.forEach((item) => {
          const month = new Date(item.date).getMonth() + 1; // Get the month (1-based)
          if (month >= 1 && month <= 3) {
            item.quarter = 1;
          } else if (month >= 4 && month <= 6) {
            item.quarter = 2;
          } else if (month >= 7 && month <= 9) {
            item.quarter = 3;
          } else if (month >= 10 && month <= 12) {
            item.quarter = 4;
          }
        });
        return data;
      };

      this.period = addCalendarQuarters(this.period);

      const grouped = this.period.reduce((acc, item) => {
        const year = new Date(item.date).getFullYear();
        const key = `${year}-Q${item.quarter}`;

        if (!acc[key]) {
          acc[key] = {
            quarter: item.quarter,
            year,
            months: [],
            sumPayments: 0,
            sumCosts: 0,
            refund: 0,
            startIndex: Infinity,
            endIndex: -Infinity,
          };
        }

        const group = acc[key];
        group.months.push(item.name);
        group.sumPayments += item.sumPayments || 0;
        group.sumCosts += item.sumCosts || 0;
        group.refund += item.refund || 0;
        group.startIndex = Math.min(
          group.startIndex,
          item.startIndex || Infinity
        );
        group.endIndex = Math.max(group.endIndex, item.endIndex || -Infinity);

        return acc;
      }, {});

      this.quarters = Object.values(grouped);

      // this.quarters = this.period.filter((e) => {
      //   if (e.id % 3 === 0) {
      //     return e;
      //   }
      // });

      // const lastCalculatedDateObject = this.quarters[this.quarters.length - 1];

      // const lastCalculatedDate = new Date(lastCalculatedDateObject.date);
      // const nextDateForCalculation = new Date(
      //   lastCalculatedDate.setMonth(lastCalculatedDate.getMonth() + 1)
      // );

      const getPeriodEndYear = this.period[this.period.length - 1].year;
      // const formattedPeriodEndMonth = new Date(
      //   nextDateForCalculation
      // ).toLocaleString("cs", {
      //   month: "2-digit",
      // });

      const getPeriodEndDate = this.period[this.period.length - 1].date;
      const getPeriodEndMonth = new Date(getPeriodEndDate).toLocaleString(
        "cs",
        {
          month: "2-digit",
        }
      );

      const periodEndDate = `${getPeriodEndYear}-${getPeriodEndMonth}`;
      this.$emit("periodEndDate", periodEndDate);
    },

    getCostsAndPayments() {
      this.costs = [];
      if (this.firstYear) {
        for (let i = 0; i < 12; i++) {
          switch (i) {
            case 0:
              this.costs.push(970);
              this.payments[i].value = 517;
              break;
            case 1:
              this.costs.push(2060);
              this.payments[i].value = 1207;
              break;
            default:
              this.costs.push(2180);
              this.payments[i].value = 2180;
              break;
          }
        }
      } else {
        for (let i = 0; i < 12; i++) {
          this.costs.push(2180);
          this.payments[i].value = 2180;
        }
      }
    },

    async generateRefundTable() {
      this.startMonth = await this.getMonth(this.startDate);
      await this.generatePeriod();
      await this.getCostsAndPayments();
      await this.calculateSumPayments();
      await this.calculateRefund();
      await this.calculateTotalCosts();
      await this.calculateTotalRefunds();
      this.calculateRealCosts();
    },

    calculateRealCosts() {
      this.realCosts = []; // Initialize the array to store cumulative real costs
      let cumulativeSum = 0; // Variable to track cumulative sum

      for (let i = 0; i < 12; i++) {
        if (this.firstYear) {
          cumulativeSum += this.payments[i].value;
          if (this.period[i - 1]?.id > this.period[i]?.id) {
            cumulativeSum = this.payments[i].value;
          }

          if (cumulativeSum <= this.insuranceType) {
            this.realCosts[i] = this.costs[i];
          } else {
            let refund = 0;

            refund = cumulativeSum - this.insuranceType;

            if (refund >= this.insuranceType && this.firstYear) {
              refund = this.payments[i].value;
            }

            this.realCosts[i] =
              this.costs[i] - refund < 0 ? 0 : this.costs[i] - refund;
          }
        } else {
          switch (this.insuredType) {
            case "between18and65":
              if (this.period[i].id === 1) {
                this.realCosts[i] = 2180;
              } else if (this.period[i].id === 2) {
                this.realCosts[i] = 2180;
              } else if (this.period[i].id === 3) {
                this.realCosts[i] = 640;
              } else {
                this.realCosts[i] = 0;
              }
              break;
            case "between65adn69":
              if (this.period[i].id === 1) {
                this.realCosts[i] = 1000;
              } else {
                this.realCosts[i] = 0;
              }
              break;
            case "above70":
              if (this.period[i].id === 1) {
                this.realCosts[i] = 500;
              } else {
                this.realCosts[i] = 0;
              }
              break;
            case "ZTP":
              if (this.period[i].id === 1) {
                this.realCosts[i] = 500;
              } else {
                this.realCosts[i] = 0;
              }
              break;
            default:
              return;
          }
        }
      }
    },

    getColsNumber(quarter) {
      if (quarter.endIndex < 3) {
        quarter.startIndex = 0;
        return quarter.endIndex + 1;
      } else {
        quarter.startIndex = quarter.endIndex - 2;
        return 3;
      }
    },

    getRefundDate(quarter) {
      const quarterNumber = quarter.id / 3;
      switch (quarterNumber) {
        case 1:
          return "nejpozději 30.5.";
        case 2:
          return "nejpozději 29.8.";
        case 3:
          return "nejpozději 29.11.";
        case 4:
          return "nejpozději 1.3.";
        default:
          return "";
      }
    },

    calculateSumPayments() {
      this.quarters.forEach((quarter) => {
        this.sumPayments = 0;
        this.sumCosts = 0;
        for (let i = quarter.startIndex; i <= quarter.endIndex; i++) {
          this.sumPayments += this.payments[i].value;
          this.sumCosts += this.costs[i];
          this.$set(quarter, "sumPayments", this.sumPayments);
          this.$set(quarter, "sumCosts", this.sumCosts);
        }
      });
    },

    calculateTotalCosts() {
      this.totalCosts = 0;
      for (let i = 0; i < this.quarters.length; i++) {
        this.totalCosts += this.quarters[i].sumCosts;
      }
    },

    calculateTotalRefunds() {
      this.totalRefund = 0;
      for (let i = 0; i < this.quarters.length; i++) {
        this.totalRefund += this.quarters[i].refund;
      }
    },

    calculateRefund() {
      this.sumRefunds = 0;
      const year = new Date(this.startDate).getFullYear();

      for (let i = 0; i < this.quarters.length; i++) {
        if (this.sumRefunds < this.insuranceType && this.firstYear) {
          this.sumRefunds += this.quarters[i].sumPayments;
          const refund =
            this.sumRefunds - this.insuranceType < 0
              ? 0
              : this.sumRefunds - this.insuranceType;
          this.$set(this.quarters[i], "refund", refund);
        } else if (this.quarters[i].id / 3 === 1) {
          this.sumRefunds += this.quarters[i].sumPayments;
          const refund =
            this.sumRefunds - this.insuranceType < 0
              ? 0
              : this.sumRefunds - this.insuranceType;
          this.$set(this.quarters[i], "refund", refund);
        } else {
          this.$set(this.quarters[i], "refund", this.quarters[i].sumPayments);
        }
        if (i !== 0 && this.quarters[i - 1].id > this.quarters[i].id) {
          this.sumRefunds = 0;
          this.sumRefunds += this.quarters[i].sumPayments;
          const refund =
            this.sumRefunds - this.insuranceType < 0
              ? 0
              : this.sumRefunds - this.insuranceType;

          this.$set(this.quarters[i], "refund", refund);
        }

        if (this.quarters[i].id / 3 === 4) {
          this.$set(this.quarters[i], "refundYear", year + 1);
        }

        if (i !== 0 && this.quarters[i - 1].refundYear) {
          this.$set(this.quarters[i], "refundYear", year + 1);
        }
      }
    },
  },

  computed: {
    startYear() {
      return new Date(this.startDate).getFullYear();
    },
    totalPaymentForYear() {
      return this.realCosts.reduce((acc, curr) => acc + curr, 0);
    },
    insuranceType() {
      switch (this.insuredType) {
        case "between18and65":
          return 5000;
        case "between65adn69":
          return 1000;
        case "above70":
          return 500;
        case "ZTP":
          return 500;
        default:
          return 5000;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  li {
    margin-bottom: 1rem;
    color: var(--v-primary-base);
  }
}

.cost-row {
  position: relative;

  .label {
    position: absolute;
    left: -7rem;
    top: 1rem;
  }
}

.payment-row {
  position: relative;

  .label {
    position: absolute;
    left: -14rem;
    top: 1rem;
  }
}

.summary-box {
  background: #143774;
}

.quarter-divider {
  border-right: 3px dotted gainsboro;
  white-space: nowrap;
  margin-right: -0.3rem;
}

.v-expansion-panels {
  border-radius: 1rem;
}

.v-expansion-panel--active {
  border-radius: 1rem !important;
}
</style>
