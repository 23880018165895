<template>
  <div class="login">
    <div class="text-right">
      <UserTypeSwitch class="py-8 ml-auto" />
    </div>
    <div class="login__card pa-12">
      <div class="d-md-flex pt-0">
        <div class="mr-12">
          <h1 class="white--text text-left">Přihlášení pacienta</h1>
          <div class="divider"></div>
          <div class="white--text text-left mb-8">
            <div class="mb-4">
              Registrací zdarma do sekce pro pacienty
              <strong>získáte další výhody</strong>
            </div>
            <ul>
              <li>
                <strong>Přístup do sekce Moje léčba</strong> po zadání čísla
                šarže z krabičky léku, kde najdete informace o přípravcích,
                které užíváte.
              </li>
              <li>
                <strong>Možnost přidat</strong> více léčivých přípravků /
                pomůcek.
              </li>
              <li>
                <strong>Praktické materiály</strong> pro každodenní život s
                diabetem ke stažení / zaslání.
              </li>
            </ul>
          </div>
        </div>
        <div class="form-wrapper">
          <div class="white--text mb-4 text-center text-h6">
            Přihlasit se pomocí
          </div>
          <v-tabs
            v-model="tab"
            grow
            hide-slider
            class="mb-4"
            active-class="login-tab--active"
          >
            <v-tab
              class="login-tab"
              v-for="tabItem in tabItems"
              :key="tabItem.name"
            >
              <div>{{ tabItem.name }}</div>
            </v-tab>
          </v-tabs>

          <LoginForm class="mt-8" :loginByEmail="loginByEmail" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "@/components/forms/LoginForm.vue";
import UserTypeSwitch from "../../components/elements/UserTypeSwitch.vue";
export default {
  components: { LoginForm, UserTypeSwitch },
  data() {
    return {
      tab: 0,
      loginByEmail: false,
      tabItems: [
        {
          name: "Šarže produktu",
        },
        {
          name: "Email",
        },
      ],
    };
  },
  watch: {
    tab() {
      this.tab === 0 ? (this.loginByEmail = false) : (this.loginByEmail = true);
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  max-width: 70rem;
  margin: auto;

  &__card {
    position: relative;
    background: var(--v-primary-base);

    margin: auto;
  }
}

.form-wrapper {
  width: 100%;
}

.login-tab {
  min-width: 50%;
  &--active {
    background: var(--v-secondary-base);
  }
}

.divider {
  border: 2px solid var(--v-accent-base);
  margin: 1rem auto 2rem;
  max-width: 100%;
}

@media screen and(min-width: 960px) {
  .login {
    &__background {
      height: 20rem;
    }
  }
}
</style>
