<template>
  <div class="product-detail-page">
    <div v-if="product" class="product-detail-page__ribbon"></div>
    <div>
      <ProductDetail v-if="product" :product="product" class="mt-12" />
      <div v-if="product" class="container">
        <div v-if="routeName === 'RYBELSUS'">
          <refund-calculator></refund-calculator>
        </div>
        <div
          class="text-h5 mb-4 mt-12 primary--text"
          v-if="product.materials.length"
        >
          Materiály ke stažení
        </div>
        <DownloadCardGrid
          :color="product.theme.primary"
          v-if="product"
          :cards="product.materials"
          class="mb-12"
        />
        <div v-if="product" class="grey--text mb-8">
          Vezměte prosím na vědomí, že obsah této webové stránky a materiálů ke
          stažení nenahrazuje rady lékaře nebo zdravotní sestry, lékařskou
          konzultaci ani příbalovou informaci pro pacienta týkající se přípravku
          {{ product.name }}<sup>®</sup>. Další informace naleznete v příbalové
          informaci pro pacienty k přípravku {{ product.name }}<sup>®</sup>.
          Pokud máte další otázky nebo potřebujete poradit, obraťte se na svého
          lékaře.
        </div>
        <div v-if="product.packageInfos" class="mb-8">
          <div class="text-h5 mb-4 mt-12 primary--text">
            Příbalové informace
          </div>
          <div
            class="mb-4"
            v-for="(packageInfo, i) in product.packageInfos"
            :key="i"
          >
            <a
              :href="packageInfo.link"
              target="_blank"
              rel="noopener noreferrer"
              v-html="packageInfo.name"
            ></a>
          </div>
        </div>

        <DiabetesCertificateCard v-if="showDiabeticProduct" />
        <CaseCard v-if="showDiabeticProduct" class="mb-4" />
        <StickerBanner v-if="showDiabeticProduct" class="mb-12" />
      </div>
    </div>
  </div>
</template>

<script>
import CaseCard from "../../components/CaseCard.vue";
import DiabetesCertificateCard from "../../components/DiabetesCertificateCard.vue";
import DownloadCardGrid from "../../components/DownloadCardGrid.vue";
import RefundCalculator from "../../components/RefundCalculator.vue";
import ProductDetail from "../../components/ProductDetail.vue";
import useListMedicines from "../../use/listMedicines";

import ProductsContent from "./ProductsContent.json";
import StickerBanner from "../../components/StickerBanner.vue";
import useApicall from "../../use/apiCall";

export default {
  components: {
    ProductDetail,
    DownloadCardGrid,
    CaseCard,
    DiabetesCertificateCard,
    RefundCalculator,
    StickerBanner,
  },

  setup() {
    const { getData, postData } = useApicall();
    const { listMedicines } = useListMedicines();
    return {
      getData,
      postData,
      listMedicines,
    };
  },

  data() {
    return {
      productsContent: ProductsContent,
      products: null,
      product: {
        theme: {
          header: "#fff",
        },
        materials: [],
      },
      routeName: null,
    };
  },

  methods: {
    async addBatchNumberForLoginUser(batchNumberValue) {
      const response = await this.postData(
        "users/batch-numbers",
        { batchNumbers: batchNumberValue },
        null,
        false
      );

      if (response) {
        this.$store.dispatch("fetchProducts");
        console.log("addBatchNumberForLoginUser", response);
      }
    },
  },

  computed: {
    showDiabeticProduct() {
      return this.routeName !== "RYBELSUS" && this.routeName !== "SAXENDA";
    },

    isLogged() {
      return !!this.$store.getters.getUser;
    },

    storeBatchNumbers() {
      return this.$store.getters.getBatchNumbers;
    },
  },

  async mounted() {
    if (this.isLogged && this.storeBatchNumbers) {
      await this.addBatchNumberForLoginUser(this.storeBatchNumbers);
      this.$store.commit("setBatchNumbers", null);
    }

    const medicines = await this.listMedicines();

    const isAllowToSeeMedicineDetail = () => {
      if (medicines) {
        return medicines.some((medicine) => {
          return (
            medicine.name.toUpperCase() ===
            this.$route.params.name.toUpperCase()
          );
        });
      } else {
        return false;
      }
    };

    const allowed = await isAllowToSeeMedicineDetail();

    if (!allowed) {
      this.$router.push({ name: "Products" });
    } else {
      this.productsContent.find((content) => {
        this.routeName = this.$route.params.name.toUpperCase();
        const contentName = content.name.toUpperCase();

        if (contentName === this.routeName) {
          this.product = content;
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.product-detail-page {
  &__ribbon {
    background: v-bind("product.theme.header");
    width: 100%;
    height: 1rem;
  }
}
</style>
