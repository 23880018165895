<template>
  <div>
    <div class="mb-4">
      <v-row>
        <v-col md="6" cols="12">
          <div class="border-card">
            <div class="title mb-4">Informace o glykovaném hemoglobinu</div>
            <v-row>
              <v-col md="8">Výchozí hodnota glykovaného hemogloginu:</v-col>
              <v-col class="text-right"
                >{{
                  hemoglobins[0]?.value
                    ? `${hemoglobins[0]?.value} mmol/mol`
                    : "Nebyl zaznamenán záznam"
                }}
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col md="8">Záznam výchozí hodnoty:</v-col>
              <v-col class="text-right">{{
                hemoglobins[0]?.trackedAtLocal
                  ? getCzechDateWithoutTime(hemoglobins[0]?.trackedAtLocal)
                  : "Nebyl zaznamenán záznam"
              }}</v-col>
            </v-row>
            <v-row>
              <v-col md="8">Aktuální hodnota glykovaného hemogloginu:</v-col>
              <v-col class="text-right"
                >{{
                  hemoglobins[hemoglobins.length - 1]?.value
                    ? `${hemoglobins[hemoglobins.length - 1]?.value} mmol/mol`
                    : "Nebyl zaznamenán záznam"
                }}
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col md="8">Aktuální hodnota glykovaného hemogloginu:</v-col>
              <v-col class="text-right">{{
                hemoglobins[hemoglobins.length - 1]?.trackedAtLocal
                  ? getCzechDateWithoutTime(
                      hemoglobins[hemoglobins.length - 1]?.trackedAtLocal
                    )
                  : "Nebyl zaznamenán záznam"
              }}</v-col>
            </v-row>
          </div>
        </v-col>
        <v-col md="6">
          <div class="white">
            <LineChart :statsData="weightData" />
          </div>
          <div v-if="print" class="mt-4">
            Graf zobrazuje pouze body s dostupnými daty. Pokud některé údaje v
            grafu chybí, znamená to, že pro dané období nebyla data vyplněna.
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import LineChart from "@/components/charts/LineChart.vue";
import useApiCall from "@/use/apiCall";
import useFormatDate from "@/use/formatDate";

export default {
  components: {
    LineChart,
  },

  setup() {
    const { getData } = useApiCall();
    const { getCzechDateWithoutTime } = useFormatDate();

    return {
      getData,
      getCzechDateWithoutTime,
    };
  },

  data() {
    return {
      hemoglobins: [],
    };
  },

  props: {
    patient: {
      type: Object,
      required: true,
    },
    print: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    weightData() {
      return {
        labels: this.hemoglobins.map((hemoglobin) =>
          this.getCzechDateWithoutTime(hemoglobin.trackedAtLocal)
        ),
        data: this.hemoglobins.map((hemoglobin) => hemoglobin.value),
      };
    },
  },

  async mounted() {
    await this.fetchHemoglobin();
  },

  methods: {
    async fetchHemoglobin() {
      const patientId = Number(this.$route.params.id);
      const from = this.$route.query.from;
      const to = this.$route.query.to;

      const params = {
        patientId,
        orderBy: "trackedAtAsc",
      };

      if (from) {
        params.from = from;
      }

      if (to) {
        params.to = to;
      }

      const { hemoglobins } = await this.getData(`/glycated-hemoglobins/`, {
        params,
      });

      this.hemoglobins = hemoglobins;
    },
  },
};
</script>
