<template>
  <div>
    <v-row>
      <v-col :lg="print ? 12 : 6" cols="12">
        <div class="border-card">
          <div class="text-h6 mb-2">Léčba</div>
          <div class="treatments-container">
            <v-timeline align-top dense>
              <v-timeline-item
                v-for="t in treatments"
                :key="t.d"
                :color="getDotColor(t)"
                small
                fill-dot
              >
                <div class="pr-12">
                  <div class="mb-1 grey--text text--darken-1">
                    Zahájena: {{ getCzechDateWithoutTime(t.startedOnLocal) }}
                  </div>
                  <div class="mb-1 grey--text text--darken-1">
                    {{
                      t.canceledAt
                        ? `Ukončena: ${getCzechDateWithoutTime(t.canceledAt)}`
                        : "Aktivní do teď"
                    }}
                  </div>

                  <div class="treatment-card pa-2">
                    <div class="mb-2">
                      <div>Dávka: {{ getDosageType(t.dosageType) }}</div>
                    </div>
                    <div>
                      Bazální inzulín:
                      {{ t.basalInsulin.isTaking ? "Ano" : "Ne" }}
                      <div v-if="t.basalInsulin.isTaking">
                        <v-divider class="my-1"></v-divider>
                        <div>
                          <small>
                            Název bazálního inzulínu:
                            {{
                              t.basalInsulin.medicationName
                                ? t.basalInsulin.medicationName
                                : "-"
                            }}
                          </small>
                        </div>
                        <div>
                          <small>
                            Dávka bazálního inzulínu:
                            {{
                              t.basalInsulin.dosageUnits
                                ? t.basalInsulin.dosageUnits
                                : "-"
                            }}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-timeline-item>
            </v-timeline>
          </div>
        </div>
      </v-col>
      <v-col v-show="!print" class="no-break">
        <div class="border-card">
          <div class="d-flex justify-space-between mb-4 align-center">
            <div>
              {{ calendarTitle }}
            </div>
            <div class="d-flex">
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="loading-container">
            <v-progress-linear
              v-if="calendarLoading"
              indeterminate
              color="accent"
            ></v-progress-linear>
          </div>
          <v-calendar
            type="month"
            locale="cs-CZ"
            :weekdays="weekdays"
            v-model="calendarValue"
            ref="calendar"
            @change="updateTitle"
          >
            <template v-slot:day-label="{ day, date }">
              <div class="pa-4">
                <RybelsusStatusIcon :intake="getDayIntake(date)">
                  {{ day }}
                </RybelsusStatusIcon>
              </div>
            </template>
          </v-calendar>
        </div>

        <div class="mt-4 border-card">
          <div class="title mb-4">Legenda</div>

          <div
            class="d-flex align-center mb-2"
            v-for="(l, i) in legend"
            :key="i"
          >
            <RybelsusStatusIcon :intake="l" class="mr-2"> </RybelsusStatusIcon>
            <div v-html="l.text"></div>
          </div>

          <div class="d-flex align-center">
            <RybelsusStatusIcon :intake="null" class="mr-2">
            </RybelsusStatusIcon>
            <div>
              <div>Chybí záznam o přípravku Rybelsus<sup>®</sup></div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import PatientStatusIcon from "@/components/icons/PatientStatusIcon.vue";
import RybelsusStatusIcon from "@/components/icons/RybelsusStatusIcon.vue";
import useApiCall from "@/use/apiCall";
import useFormatDate from "@/use/formatDate";

export default {
  components: {
    // PatientStatusIcon,
    RybelsusStatusIcon,
  },

  setup() {
    const { getData } = useApiCall();
    const { getCzechDate, getDateToSend, getCzechDateWithoutTime } =
      useFormatDate();

    return {
      getData,
      getCzechDate,
      getCzechDateWithoutTime,
      getDateToSend,
    };
  },

  props: {
    patient: {
      type: Object,
      required: true,
    },
    print: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      calendarValue: this.getStartOfMonth(new Date()),
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      calendarTitle: "",
      treatments: [],
      intakes: [],
      calendarLoading: false,
      legend: [
        {
          status: "accepted",
          dosageType: "rybelsus3Mg",
          text: "Rybelsus<sup>®</sup> 3 mg byl vzat",
        },
        {
          status: "accepted",
          dosageType: "rybelsus7Mg",
          text: "Rybelsus<sup>®</sup> 7 mg byl vzat",
        },
        {
          status: "accepted",
          dosageType: "rybelsus14Mg",
          text: "Rybelsus<sup>®</sup> 14 mg byl vzat",
        },

        // {
        //   status: "accepted",
        //   dosageType: "rybelsus1P5Mg",
        //   text: "Rybelsus<sup>®</sup> 1,5 mg byl vzat",
        // },
        // {
        //   status: "accepted",
        //   dosageType: "rybelsus4Mg",
        //   text: "Rybelsus<sup>®</sup> 4 mg byl vzat",
        // },
        // {
        //   status: "accepted",
        //   dosageType: "rybelsus9Mg",
        //   text: "Rybelsus<sup>®</sup> 9 mg byl vzat",
        // },
        // {
        //   status: "rejected",
        //   dosageType: "rybelsus3Mg",
        //   text: "Přípravek Rybelsus<sup>®</sup> nebyl vzat",
        // },
      ],
    };
  },

  async mounted() {
    this.updateTitle();
    await this.fetchTreatment();
    await this.fetchTreatmentIntakes();
  },

  methods: {
    async fetchTreatment() {
      const patientId = Number(this.$route.params.id);

      const params = {
        patientId: patientId,
        orderBy: "createdAtDesc",
      };

      const { treatments } = await this.getData("rybelsus/treatments", {
        params,
      });

      this.treatments = treatments;
    },

    async fetchTreatmentIntakes() {
      this.intakes = [];
      this.calendarLoading = true;
      const patientId = Number(this.$route.params.id);

      const params = {
        patientId: patientId,
        from: this.getDateToSend(this.getStartOfMonth(this.calendarValue)),
        end: this.getDateToSend(this.getEndOfMonth(this.calendarValue)),
      };

      const { intakes } = await this.getData("rybelsus/treatments/intakes", {
        params,
      });

      this.intakes = intakes;
      this.calendarLoading = false;
    },

    getDosageType(dosageType) {
      if (dosageType === "rybelsus3Mg") {
        return "3 mg";
      } else if (dosageType === "rybelsus7Mg") {
        return "7 mg";
      } else if (dosageType === "rybelsus14Mg") {
        return "14 mg";
      } else {
        return "-";
      }
    },

    getDotColor(treatment) {
      if (treatment.dosageType === "rybelsus1P5Mg") {
        return "#0DBC45";
      } else if (treatment.dosageType === "rybelsus4Mg") {
        return "#EA6473";
      } else if (treatment.dosageType === "rybelsus9Mg") {
        return "#2293B8";
      } else if (treatment.dosageType === "rybelsus3Mg") {
        return "#b7dbc2";
      } else if (treatment.dosageType === "rybelsus7Mg") {
        return "#efbec3";
      } else if (treatment.dosageType === "rybelsus14Mg") {
        return "#9bdaee";
      } else {
        return "grey";
      }
    },

    getDayIntake(date) {
      const intake = this.intakes.find((i) => {
        const formattedDate = this.getDateToSend(i.createdAtLocal);
        if (formattedDate === date) {
          return i;
        }
      });

      return intake;
    },

    getStartOfMonth(date) {
      return new Date(date.getFullYear(), date.getMonth(), 1);
    },

    getEndOfMonth(date) {
      return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    },

    async prev() {
      this.calendarValue = this.getStartOfMonth(
        new Date(
          this.calendarValue.getFullYear(),
          this.calendarValue.getMonth() - 1
        )
      );
      await this.fetchTreatmentIntakes();
    },
    async next() {
      this.calendarValue = this.getStartOfMonth(
        new Date(
          this.calendarValue.getFullYear(),
          this.calendarValue.getMonth() + 1
        )
      );
      await this.fetchTreatmentIntakes();
    },
    updateTitle() {
      this.calendarTitle = this.$refs.calendar.title || "";
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-container {
  height: 4px;
}

.treatment-card {
  border-radius: 8px;
  border: 1px solid #e0e0e0;
}

::v-deep .v-timeline-item__dot--small {
  box-shadow: none;
  width: 12px;
  height: 12px;
}

::v-deep.v-calendar {
  .v-outside {
    .status {
      background-color: transparent;
      border: none;
    }
  }
}

@media print {
  .no-break {
    page-break-inside: avoid; /* Avoid breaking inside the element */
    break-inside: avoid; /* Ensures compatibility with modern browsers */
    display: block;
  }
}
</style>
